/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const {isAuthorized, checkAuthorization} = useAuth()
  const intl = useIntl()

  return (
    <div style={{overflow: 'auto'}}>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={'Dashboard'}
        fontIcon='bi-app-indicator'
      />

      {checkAuthorization(['read_role', 'read_user'], true) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-2 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Access</span>
            </div>
          </div>
          <>
            {isAuthorized('read_user') && (
              <SidebarMenuItem to='/user' icon='user' title={'User'} fontIcon='bi-app-indicator' />
            )}

            {isAuthorized('read_role') && (
              <SidebarMenuItem
                to='/role'
                icon='security-user'
                title={'Role'}
                fontIcon='bi-app-indicator'
              />
            )}
          </>
        </>
      )}

      {checkAuthorization(['read_company', 'read_department', 'read_inventory'], true) && (
        <>
          <div className='menu-item'>
            <div className='menu-content pt-2 pb-2'>
              <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Company</span>
            </div>
          </div>
          <>
            {isAuthorized('read_company') && (
              <SidebarMenuItem
                to='/company'
                icon='bank'
                title={'Company'}
                fontIcon='bi-app-indicator'
              />
            )}
            {isAuthorized('read_department') && (
              <SidebarMenuItem
                to='/department'
                icon='trailer'
                title={'Department'}
                fontIcon='bi-app-indicator'
              />
            )}
            {isAuthorized('read_inventory') && (
              <SidebarMenuItem
                to='/inventory'
                icon='logistic'
                title={'Inventory'}
                fontIcon='bi-app-indicator'
              />
            )}
            {isAuthorized('read_maintenance_request') && (
              <SidebarMenuItem
                to='/maintenance-request'
                icon='setting-3'
                title={'Maintenance Request'}
                fontIcon='bi-app-indicator'
              />
            )}
            {isAuthorized('read_maintenance_schedule') && (
              <SidebarMenuItem
                to='/maintenance-schedule'
                icon='setting-3'
                title={'Maintenance Schedule'}
                fontIcon='bi-app-indicator'
              />
            )}
          </>
        </>
      )}
    </div>
  )
}

export {SidebarMenuMain}

// <div className='menu-item'>
//     <div className='menu-content pt-2 pb-2'>
//         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Vehicle</span>
//     </div>
// </div>
// <>
//     {isAuthorized('read_association') && (
//         <SidebarMenuItem
//             to='/association'
//             icon='data'
//             title={'Association'}
//             fontIcon='bi-app-indicator'
//         />
//     )}
//     {isAuthorized('read_vehicle') && (
//         <SidebarMenuItem to='/vehicle' icon='car' title={'Vehicle'} fontIcon='bi-app-indicator' />
//     )}
// </>
//
// <div className='menu-item'>
//     <div className='menu-content pt-2 pb-2'>
//         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Variables</span>
//     </div>
// </div>
// <>
//     {isAuthorized('read_level') && (
//         <SidebarMenuItem to='/level' icon='ranking' title={'Level'} fontIcon='bi-app-indicator' />
//     )}
//     {isAuthorized('read_plate_type') && (
//         <SidebarMenuItem
//             to='/plate-type'
//             icon='parcel-tracking'
//             title={'Plate Type'}
//             fontIcon='bi-app-indicator'
//         />
//     )}
//     {isAuthorized('read_seat_number') && (
//         <SidebarMenuItem
//             to='/seat-number'
//             icon='burger-menu-3'
//             title={'Seat Number'}
//             fontIcon='bi-app-indicator'
//         />
//     )}
// </>
//
// <div className='menu-item'>
//     <div className='menu-content pt-2 pb-2'>
//         <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Routes</span>
//     </div>
// </div>
// <>
//     {isAuthorized('read_route') && (
//         <SidebarMenuItem to='/route' icon='route' title={'Route'} fontIcon='bi-app-indicator' />
//     )}
//
//     {isAuthorized('read_station') && (
//         <SidebarMenuItem
//             to='/station'
//             icon='lots-shopping'
//             title={'Station'}
//             fontIcon='bi-app-indicator'
//         />
//     )}
// </>
