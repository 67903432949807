export const AuthRoutes = {
  BASE: '/auth',
  LOGIN: '/auth/login',
}

export const RoleRoutes = {
  BASE: '/roles',
}

export const SeatNumberRoutes = {
  BASE: '/seat_numbers',
}

export const PlateTypeRoutes = {
  BASE: '/plate_types',
}

export const VehicleRoutes = {
  BASE: '/vehicles',
}

export const RouteRoutes = {
  BASE: '/routes',
}

export const StationRoutes = {
  BASE: '/stations',
}

export const AccessRuleRoutes = {
  BASE: '/access_rules',
}

export const UserRoutes = {
  BASE: '/users',
}

export const AssignationRoutes = {
  BASE: '/assignations',
}

export const LevelRoutes = {
  BASE: '/levels',
}

export const AssociationRoutes = {
  BASE: '/associations',
}

export const RegionRoutes = {
  BASE: '/regions',
}

export const ZoneRoutes = {
  BASE: '/zones',
}

export const CityRoutes = {
  BASE: '/cities',
}

export const CompanyRoutes = {
  BASE: '/companies',
}

export const DepartmentRoutes = {
  BASE: '/departments',
}

export const InventoryRoutes = {
  BASE: '/inventories',
}

export const MaintenanceRequestRoutes = {
  BASE: '/maintenance-requests',
}

export const MaintenanceScheduleRoutes = {
  BASE: '/maintenance-schedules',
}

export const MaintenanceReportRoutes = {
  BASE: '/maintenance-reports',
}

export const FileRoutes = {
  BASE: '/files',
}
