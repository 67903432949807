import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import RoleProtection from './RoleProtectection'

const PrivateRoutes = () => {
  const RegionsPage = lazy(() => import('../modules/apps/region/RegionsPage'))
  const UsersPage = lazy(() => import('../modules/apps/user/UsersPage'))
  const RolesPage = lazy(() => import('../modules/apps/role/RolesPage'))
  const CitiesPage = lazy(() => import('../modules/apps/city/CitiesPage'))
  const CompaniesPage = lazy(() => import('../modules/apps/company/CompaniesPage'))
  const DepartmentsPage = lazy(() => import('../modules/apps/department/DepartmentsPage'))
  const InventoriesPage = lazy(() => import('../modules/apps/inventory/InventoriesPage'))
  const MaintenanceRequestsPage = lazy(
    () => import('../modules/apps/maintenance-request/MaintenanceRequestsPage')
  )
  const MaintenanceSchedulesPage = lazy(
    () => import('../modules/apps/maintenance-schedule/MaintenanceSchedulesPage')
  )
  const AssociationsPage = lazy(() => import('../modules/apps/association/AssociationsPage'))
  const ZonesPage = lazy(() => import('../modules/apps/zone/ZonesPage'))
  const LevelPage = lazy(() => import('../modules/apps/level/LevelsPage'))
  const SeatNumbersPage = lazy(() => import('../modules/apps/seat-number/SeatNumbersPage'))
  const PlateTypesPage = lazy(() => import('../modules/apps/plate-type/PlateTypesPage'))
  const RoutesPage = lazy(() => import('../modules/apps/route/RoutesPage'))
  const VehiclesPage = lazy(() => import('../modules/apps/vehicle/VehiclesPage'))
  const StationsPage = lazy(() => import('../modules/apps/station/StationsPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route
          path='association/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_association'} content={<AssociationsPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='company/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_company'} content={<CompaniesPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='department/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_department'} content={<DepartmentsPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='inventory/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_inventory'} content={<InventoriesPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='maintenance-request/*'
          element={
            <SuspenseView>
              <RoleProtection
                privilege={'read_maintenance_request'}
                content={<MaintenanceRequestsPage />}
              />
            </SuspenseView>
          }
        />

        <Route
          path='maintenance-schedule/*'
          element={
            <SuspenseView>
              <RoleProtection
                privilege={'read_maintenance_schedule'}
                content={<MaintenanceSchedulesPage />}
              />
            </SuspenseView>
          }
        />

        <Route
          path='plate-type/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_plate_type'} content={<PlateTypesPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='seat-number/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_seat_number'} content={<SeatNumbersPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='user/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_user'} content={<UsersPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='level/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_level'} content={<LevelPage />} />
            </SuspenseView>
          }
        />
        <Route
          path='station/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_station'} content={<StationsPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='region/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_region'} content={<RegionsPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='vehicle/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_vehicle'} content={<VehiclesPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='zone/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_zone'} content={<ZonesPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='city/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_city'} content={<CitiesPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='role/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_role'} content={<RolesPage />} />
            </SuspenseView>
          }
        />

        <Route
          path='route/*'
          element={
            <SuspenseView>
              <RoleProtection privilege={'read_route'} content={<RoutesPage />} />
            </SuspenseView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspenseView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
